import { FC, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CheckIcon from '../../../components/shared/icon/CheckIcon';

type PasswordValidatorProps = {
  password: string;
  isValid: (isValid: boolean) => void;
};

const MIN_PASSWORD_LENGTH = 8;

type Condition = {
  key: string;
  validate: (value: string) => boolean;
};

type InArr<T> = T extends readonly Condition[] ? (Condition & { key: T[number]['key'] })[] : never[];
const typeHelper = <T extends readonly Condition[]>(value: T) => value as unknown as InArr<T>;

const CONDITIONS = typeHelper([
  { key: 'capital-letter', validate: (value) => !!value.match(/[A-Z]/) },
  { key: 'symbol', validate: (value) => !!value.match(/\W/) },
  { key: 'number', validate: (value) => !!value.match(/\d/) },
  { key: 'length', validate: (value) => value.length >= MIN_PASSWORD_LENGTH },
] as const);

const PasswordValidator: FC<PasswordValidatorProps> = (props) => {
  const { password, isValid } = props;

  const { t } = useTranslation('auth');

  const validConditions = useMemo(() => CONDITIONS.map((x) => x.validate(password)), [password]);

  useEffect(() => {
    isValid(validConditions.every((x) => x));
  }, [isValid, validConditions]);

  return (
    <div className="bg-gray-5 my-6 bg-opacity-60 px-6 py-4" data-cy="password-validator">
      <div className="text-gray-1 mb-2 font-medium">{t('common.password-validation.heading')}</div>
      {CONDITIONS.map((condition, i) => (
        <div key={condition.key} data-cy={`${condition.key}-${validConditions[i] ? 'valid' : 'invalid'}`}>
          <CheckIcon className={`text-primary-2 h-6 w-6 ${validConditions[i] ? 'visible' : 'invisible'} mr-2`} />
          <span className={`${validConditions[i] ? 'text-black' : 'text-gray-2'}`}>
            <Trans t={t} i18nKey={`common.password-validation.conditions.${condition.key}`} components={{ Bold: <span className="font-medium" /> }} />
          </span>
        </div>
      ))}
    </div>
  );
};

export default PasswordValidator;
